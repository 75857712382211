/* In your Garmentline.css or another global CSS file */
.page-transition {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .page-transition-active {
    opacity: 1;
    transform: scale(1);
  }
  